import React, { useState } from 'react'
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, CardHeader } from 'reactstrap'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

import UserService from '../../../network/services/user'
import { useTranslation } from 'react-i18next'

const EditProfile = () => {
  const { t } = useTranslation()
  const [isEditable, setIsEditable] = useState(false)
  const user = useSelector((state) => state.user.user)
  const profile = useSelector((state) => state.user.profile)
  const verification = useSelector((state) => state.user.verifications)
  const { register, handleSubmit, errors, formState } = useForm()
  const [identity, setIdentity] = useState('')
  const { isSubmitting } = formState
  const submitUpdate = async (data) => {
    /*    data['first_name'] = user?.first_name ?? ''
        data['last_name'] = user?.last_name ?? ''
        data['email'] = user?.email ?? ''
        data['mobile'] = user?.mobile ?? ''*/
    // debug
    // data['street'] = "1"
    // data['building'] = "1"
    // data['unit'] = "1"
    try {

      const result = await UserService.updateProfile(user.id, data)
      // console.log(result)

      if (result.profile) {
        SweetAlert.fire({
          title: t("user.PersonalAreaInformation"),
          text: t("user.PleaseProceedToSubmit"),
          icon: 'success'
        }).then(() => {
          window.location.reload()
        })

      }

    } catch (error) {

      console.log(error)
    }
  }

  return (
    <>
      <Card>
        <CardBody>{t("user.IfYouWishToUpdate")} <a href="mailto:support@tradehall.co">support@tradehall.co</a></CardBody>
      </Card>
      <Card>
        <CardBody>

          {isEditable ? (
            <>

              <Card>
                <CardBody style={{ backgroundColor: '#ffc4c4' }}>
                  <p style={{ margin: 0, color: 'red', fontWeight: 'bold' }}>
                    {t("user.InAccordanceWithSecurity")}
                  </p>
                </CardBody>
              </Card>
              <Form
                className="theme-form"
                onSubmit={handleSubmit(submitUpdate)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '0.75rem'
                }}
              >
                <Card>
                  <CardHeader>
                    <h5>{t("user.PersonalInformation")}</h5>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.Title")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="title"
                        innerRef={register({ required: true })}
                        defaultValue={profile?.title ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="mr">{t("user.Mr")}</option>
                        <option value="ms">{t("user.MsMrs")}</option>
                      </Input>
                      <span style={{ color: 'red' }}>{errors.title && t("user.TitleIsRequired")}</span>
                    </FormGroup>

                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.IdentityCardPassportNumber")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="identification_number"
                        innerRef={register({ required: true })}
                        defaultValue=""
                        onChange={(e) => { setIdentity(e.target.value) }}
                        placeholder="e.g. 901111012345"
                      >
                      </Input>
                      <span style={{ color: 'red' }}>{errors.identity && t("user.IdentityIsRequired")}</span>
                    </FormGroup>

                    <FormGroup>
                      <Label className="col-form-label pt-0">{t("user.FirstName")}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="first_name"
                        innerRef={register({ required: true })}
                        defaultValue={user?.first_name ?? '-'}

                      />
                      <span style={{ color: 'red' }}>{errors.first_name && t("user.FirstNameRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">{t("user.LastName")}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="last_name"
                        innerRef={register({ required: true })}
                        defaultValue={user?.last_name ?? '-'}
                      />
                      <span style={{ color: 'red' }}>{errors.last_name && t("user.LastNameRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">{t("user.Email")}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="email"
                        innerRef={register({ required: true })}
                        defaultValue={user?.email ?? '-'}
                      />
                      <span style={{ color: 'red' }}>{errors.email && t("user.EmailRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.DateBirth")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="date"
                        name="dob"
                        placeholder="yyyy-mm-dd"
                        innerRef={register({
                          required: true,
                          validate: (value) =>
                            moment().diff(value, 'years') >= 18 && moment().diff(value, 'years') <= 99
                        })}
                        defaultValue={moment(profile?.dob).format('yyyy-MM-DD') ?? ''}
                      />
                      <span style={{ color: 'red' }}>{errors.dob && t("user.MustBe18Years")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.PrimaryNationality")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="nationality"
                        innerRef={register({ required: true })}
                        defaultValue={profile.nationality ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="Afganistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire">Bonaire</option>
                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Channel Islands">Channel Islands</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Island">Cocos Island</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaco">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Ter">French Southern Ter</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Great Britain">Great Britain</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="India">India</option>
                        <option value="Iran">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea North">Korea North</option>
                        <option value="Korea Sout">Korea South</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Laos">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Midway Islands">Midway Islands</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Nambia">Nambia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherland Antilles">Netherland Antilles</option>
                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                        <option value="Nevis">Nevis</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau Island">Palau Island</option>
                        <option value="Palestine">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Phillipines">Philippines</option>
                        <option value="Pitcairn Island">Pitcairn Island</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                        <option value="Republic of Serbia">Republic of Serbia</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="St Barthelemy">St Barthelemy</option>
                        <option value="St Eustatius">St Eustatius</option>
                        <option value="St Helena">St Helena</option>
                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                        <option value="St Lucia">St Lucia</option>
                        <option value="St Maarten">St Maarten</option>
                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                        <option value="Saipan">Saipan</option>
                        <option value="Samoa">Samoa</option>
                        <option value="Samoa American">Samoa American</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syria</option>
                        <option value="Tahiti">Tahiti</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Erimates">United Arab Emirates</option>
                        <option value="United States of America">United States of America</option>
                        <option value="Uraguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">Vatican City State</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                        <option value="Wake Island">Wake Island</option>
                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zaire">Zaire</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </Input>
                      <span style={{ color: 'red' }}>
                        {errors.nationality && t("user.NationalityRequired")}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.PreferredLanguage")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="language"
                        innerRef={register({ required: true })}
                        defaultValue={profile.language ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="English">{t("user.English")}</option>
                        <option value="Chinese">{t("user.Chinese")}</option>
                        <option value="Bahasa Malayu">{t("user.BahasaMalayu")}</option>
                      </Input>
                      <span style={{ color: 'red' }}>{errors.language && t("user.LanguageRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.EducationLevel")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="education"
                        innerRef={register({ required: true })}
                        defaultValue={profile.education ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="secondary">{t("user.Secondary")}</option>
                        <option value="high_school">{t("user.HighSchool")}</option>
                        <option value="bachelor">{t("user.BachelorDegree")}</option>
                        <option value="master">{t("user.MasterDegree")}</option>
                        <option value="doctorate">{t("user.Doctorate")}</option>
                        <option value="none">{t("user.NoneAbove")}</option>
                      </Input>
                      <span style={{ color: 'red' }}>{errors.education && t("user.EducationRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.EmployementStatus")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="employed"
                        innerRef={register({ required: true })}
                        defaultValue={profile.employed ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="employee">{t("user.Employee")}</option>
                        <option value="self_employed">{t("user.SelfEmployed")}</option>
                        <option value="un_employed">{t("user.Unemployed")}</option>
                        <option value="student">{t("user.Student")}</option>
                      </Input>
                      <span style={{ color: 'red' }}>{errors.employed && t("user.EmployedRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.Phone")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="mobile"
                        innerRef={register({ required: true })}
                        // disabled
                        defaultValue={user?.mobile}
                      />
                      <span style={{ color: 'red' }}>{errors.mobile && t("user.MobileRequired")}</span>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h5>{t("user.PersonalAddress")}</h5>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.CountryResidence")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="country"
                        innerRef={register({ required: true })}
                        defaultValue={profile.country ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="Afganistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire">Bonaire</option>
                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Channel Islands">Channel Islands</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Island">Cocos Island</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaco">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Ter">French Southern Ter</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Great Britain">Great Britain</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="India">India</option>
                        <option value="Iran">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea North">Korea North</option>
                        <option value="Korea Sout">Korea South</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Laos">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Midway Islands">Midway Islands</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Nambia">Nambia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherland Antilles">Netherland Antilles</option>
                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                        <option value="Nevis">Nevis</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau Island">Palau Island</option>
                        <option value="Palestine">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Phillipines">Philippines</option>
                        <option value="Pitcairn Island">Pitcairn Island</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                        <option value="Republic of Serbia">Republic of Serbia</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="St Barthelemy">St Barthelemy</option>
                        <option value="St Eustatius">St Eustatius</option>
                        <option value="St Helena">St Helena</option>
                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                        <option value="St Lucia">St Lucia</option>
                        <option value="St Maarten">St Maarten</option>
                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                        <option value="Saipan">Saipan</option>
                        <option value="Samoa">Samoa</option>
                        <option value="Samoa American">Samoa American</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syria</option>
                        <option value="Tahiti">Tahiti</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Erimates">United Arab Emirates</option>
                        <option value="United States of America">United States of America</option>
                        <option value="Uraguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">Vatican City State</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                        <option value="Wake Island">Wake Island</option>
                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zaire">Zaire</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </Input>
                      <span style={{ color: 'red' }}>{errors.country && t("user.CountryRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.ProvinceState")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="state"
                        innerRef={register({ required: true })}
                        defaultValue={profile.state ?? ''}

                      />
                      <span style={{ color: 'red' }}>{errors.state && t("user.StateRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.City")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="city"
                        innerRef={register({ required: true })}
                        defaultValue={profile.city ?? ''}
                      />
                      <span style={{ color: 'red' }}>{errors.city && t("user.CityRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.AddressLine")}1<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="address_1"
                        innerRef={register({ required: true })}
                        defaultValue={profile.address_1 ?? ''}
                      />
                      <span style={{ color: 'red' }}>{errors.address_1 && t("user.AddressRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">{t("user.AddressLine")} 2</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="address_2"
                        innerRef={register({ required: false })}
                        defaultValue={profile.address_2 ?? ''}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.ZipCode")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="zip"
                        innerRef={register({ required: true })}
                        defaultValue={profile.zip ?? ''}

                      />
                      <span style={{ color: 'red' }}>{errors.zip && t("user.ZipRequired")}</span>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h5>{t("user.FinancialBackground")}</h5>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.SourceFunding")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="financial"
                        innerRef={register({ required: true })}
                        defaultValue={profile.financial ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="savings">{t("user.Savings")}</option>
                        <option value="salary">{t("user.Salary")}</option>
                        <option value="investments">{t("user.Investments")}</option>
                        <option value="loan">{t("user.Loan")}</option>
                        <option value="other">{t("user.Other")}</option>
                      </Input>
                      <span style={{ color: 'red' }}>{errors.financial && t("user.FinancialRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.GrossAnnualIncome")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="income"
                        innerRef={register({ required: true })}
                        defaultValue={profile.income ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="8500 USD and below">{`8500 USD and below`}</option>
                        <option value="8501 USD - 14000 USD">8501 USD - 14000 USD</option>
                        <option value="14001 USD - 23000 USD">14001 USD - 23000 USD</option>
                        <option value="23001 USD and above">23001 USD and above</option>
                      </Input>
                      <span style={{ color: 'red' }}>{errors.income && t("user.IncomeRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.TotalNetWorth")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="net_worth"
                        innerRef={register({ required: true })}
                        defaultValue={profile.net_worth ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="0">{`< 1000`}</option>
                        <option value="1000">1000 - 5000</option>
                        <option value="5000">5000 - 25000</option>
                        <option value="25000">25000 - 250000</option>
                        <option value="250000">{`> 250000`}</option>
                      </Input>
                      <span style={{ color: 'red' }}>{errors.net_worth && t("user.NetWorthRequired")}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        {t("user.TradingObjectives")}
                        <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="objective"
                        innerRef={register({ required: true })}
                        defaultValue={profile.objective ?? ''}
                      >
                        <option value="" disabled>
                          {t("user.Select")}
                        </option>
                        <option value="preservation">{t("user.PreservationCapital")}</option>
                        <option value="speculation">{t("user.Speculation")}</option>
                        <option value="income">{t("user.Income")}</option>
                        <option value="hedging">{t("user.Hedging")}</option>
                      </Input>
                      <span style={{ color: 'red' }}>{errors.objective && t("user.ObjectiveRequired")}</span>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h5>{t("user.Confirmation")}</h5>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <div className="custom-control custom-checkbox mb-3">
                        <Input className="custom-control-input" id="agreement" type="checkbox" required />
                        <Label className="custom-control-label" htmlFor="agreement">
                        {t("user.IdeclareThatIHaveCarefully")} <a href='https://tradehallglobal.co/wp-content/uploads/2023/06/Client-Agreement.pdf' target='_blank'>{t("user.ClientServiceAgreement")}</a> {t("user.and")} <a href='https://tradehallglobal.co/wp-content/uploads/2023/06/Risk-Disclosure-Notice.pdf' target='_blank'>{t("user.RiskDisclosureStatement")}</a>{t("user.whichIFullyUnderstand")}

                        </Label>
                        <div className="invalid-feedback">{t("user.PleaseAgreeToTerms")}</div>
                      </div>
                    </FormGroup>

                    <Button
                      color="primary btn-block"
                      type="submit"
                      style={{ maxWidth: 150, float: 'right' }}
                    >
                      {isSubmitting ? t("user.Loading") : t("user.Submit")}
                    </Button>
                  </CardBody>
                </Card>
              </Form>
            </>

          ) : (
              <>
                <Row>
                  <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                    {t("user.Name")}

                  </Col>
                  <Col xs="12" md="6">
                    {user?.name ?? '-'}
                  </Col>
                  <hr />
                </Row>
                <Row>
                  <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                    {t("user.Email")}
                  </Col>
                  <Col xs="12" md="6">
                    {user?.email ?? '-'}
                  </Col>
                  <hr />
                </Row>
                <Row>
                  <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                    {t("user.PhoneNumber")}
                  </Col>
                  <Col xs="12" md="6">
                    {user?.mobile ?? '-'}
                  </Col>
                  <hr />
                </Row>

                {Object.keys(profile).map((detail) => {
                  if (
                    detail === 'created_at' ||
                    detail === 'updated_at' ||
                    detail === 'user_id' ||
                    detail === 'id' ||
                    detail === 'title' ||
                    detail === 'financial' ||
                    detail === 'net_worth' ||
                    detail === 'income' ||
                    detail === 'objective'


                  ) {
                    return <></>
                  }

                  if (detail === 'dob') {
                    return (
                      <Row>
                        <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                          {t("user.DateBirth")}
                        </Col>
                        <Col xs="12" md="6">
                          {moment(profile[detail]).format('yyyy-MM-DD')}
                        </Col>
                        <hr />
                      </Row>
                    )
                  }

                  // Address is special because lodash does not support punctuation
                  if (detail === 'address_1') {
                    return (
                      <Row>
                        <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                          Address 1
                        </Col>
                        <Col xs="12" md="6">
                          {profile[detail]}
                        </Col>
                        <hr />
                      </Row>
                    )
                  }

                  if (detail === 'address_2') {
                    return (
                      <Row>
                        <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                          Address 2
                        </Col>
                        <Col xs="12" md="6">
                          {profile[detail]}
                        </Col>
                        <hr />
                      </Row>
                    )
                  }

                  return (
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        {t("user." + _.startCase(detail))}
                        {/* add 'user. because the grouping for these variables are under user' */}
                      </Col>
                      <Col xs="12" md="6">
                        {_.startCase(profile[detail])}
                      </Col>
                      <hr />
                    </Row>
                  )


                })

                }


                {
                  (verification.length === 0 ||
                    (verification.length > 0 && verification[verification.length - 1].status !== 1)) && (
                    <Button
                      className="pull-right"
                      color="primary"
                      onClick={() => {
                        setIsEditable(true)
                      }}
                    >
                      {t("user.Edit")}
                    </Button>
                  )
                }
              </>
            )
          }
        </CardBody>
      </Card>
    </>
  )

}



export default EditProfile
