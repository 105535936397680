import React from 'react'
import { useForm } from 'react-hook-form'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'

import AccountService from '../../../network/services/account'
import { useTranslation } from 'react-i18next'
const InvestorPassword = ({ setShowModal }) => {
  const { register, handleSubmit, errors } = useForm()
  const account = useSelector((state) => state.dashboard.account)
  const { t } = useTranslation()
  let myRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{4,}$/;
  const handleChangePassword = async (data) => {

    try {
      if (data !== '' && account != null) {
        if (!myRegex.test(data?.new_password)) {
          SweetAlert.fire({
            title: 'Password does not meet the criteria.',
            icon: 'error'
          })
          return;
        }


        const result = await AccountService.changeInvestorPassword(account.id, data)

        if (result.success) {
          SweetAlert.fire({
            title: t("common.changeSuccessfully"),
            icon: 'success'
          })
          setShowModal(false)
        }
      } else {
        errors.showMessages()
      }
    } catch (error) {
      // SweetAlert.fire({
      //   title: error.message,
      //   icon: 'error'
      // })
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  return (
    <div>
      <Form className="theme-form" onSubmit={handleSubmit(handleChangePassword)}>
        <FormGroup>
          <Label>{t("common.MT5AccountNumber")}</Label>
          <Input
            className="form-control"
            type="text"
            name="account_login"
            defaultValue={account?.account_login}
            readOnly
            style={{ color: 'black' }}
          />
        </FormGroup>

        <FormGroup>
          <Label>{t("common.newPassword")}</Label>
          <Input
            className="form-control"
            type="text"
            name="new_password"
            innerRef={register({ required: true })}
            placeholder="e.g. Password1!"
            style={{ color: 'black' }}
          />
          <span style={{ fontSize: '12px' }}>{!errors.new_password && '*' + t("common.newPasswordRequire")}</span>
          <span style={{ color: 'red', fontSize: '12px' }}>{errors.new_password && '*' + t("common.newPasswordRequire")}</span>
        </FormGroup>

        <FormGroup className="mb-0" style={{ float: 'right' }}>
          <Button color="primary">{t("common.confirm")}</Button>
        </FormGroup>
      </Form>
    </div>
  )
}

export default InvestorPassword
